.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.scrolling-wrapper {
  margin: 0 1rem 1rem 1rem;
  overflow-x: scroll;
}

.page-wrapper {
  min-width: 400px;
}

/* .site-layout .site-layout-background {
  background: #fff;
} */

:root {
  --amplify-primary-color: #1890ff;
  --amplify-primary-tint: #7abfff;
  --amplify-primary-shade: #0068c9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
