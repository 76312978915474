.lightgreen {
  color: lightgreen;
}

.lightgrey {
  color: lightgrey;
}

.lightblue {
  color: lightblue;
}
