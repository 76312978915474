.wrap-label {
  .ant-form-item-label {
    white-space: normal;
    padding: 0.2rem;
  }
}

amplify-container {
  width: auto;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

amplify-authenticator {
  opacity: 0.95;
  margin: 1em;
}
