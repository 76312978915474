.application-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1em;
}

.header-buttons {
  display: flex;
  justify-content: center;
  max-width: 80%;
  overflow-x: hidden;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.lng .selected {
  background-color: #1890ff;
}
